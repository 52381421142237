.followable_user {
  margin: 0.5em;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 0.25em;
  overflow: hidden;
}

.followable_user > span {
  margin-left: 0.5em;
  margin-right: 0.5em;
  overflow: hidden;
  text-overflow: ellipsis;
}
