.access_request {
  margin: 1em;
}

.access_request > span > * {
  margin-right: 0.5em;
}

.access_request a {
  color: #4D4D4D;
  text-decoration: underline;
}

.followable_user {
  padding-left: 0.5em;
  padding-right: 0.5em;
  border: 1px solid #eee;
  border-radius: 0.25em;
}
