.group_summary {
  display: flex;
  flex-direction: row;
}

.group_summary h3 {
  margin-bottom: 0.5em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.group_summary > .summary {
  padding: 0.5em;
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
  justify-content: space-between;
}

.group_summary .meta {
  color: #999;
  font-size: 0.75em;
}

.group_summary .meta li {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0.5em;
  list-style-type: none;
}

.group_summary .meta .closed {
  margin-left: 0.25em;
  margin-right: 0.25em;
}
