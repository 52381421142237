.activity {
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  box-sizing: border-box;
}

.activity .summary {
  display: flex;
  flex-direction: column;
  margin-left: 0.5em;
  margin-right: 0.5em;
  color: #999;
}

.activity .description {
  flex: 1;
  overflow: hidden;
}

.activity .description h3 {
  font-weight: normal;
  font-style: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.activity .description .aggregated_verb {
  color: #999;
}

.activity .description .aggregated_verb > span {
  margin-left: 0.125em;
  margin-right: 0.125em;
  max-width: 95vw;
  box-sizing: border-box;
}

.activity .verb {
  margin-left: 0.125em;
  margin-right: 0.125em;
}

.activity .published a {
  color: #999999;
  margin-left: 0.25em;
  margin-right: 0.25em;
}

.activity .meta {
  font-size: 0.75em;
  color: #999;
}

.activity .action {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.activity .name {
  color: #999999;
}

.activity .article {
  display: flex;
  flex-direction: row;
  margin: 1em;
  margin-bottom: 0.5em;
  max-width: 100vw;
}

.activity .article a {
  overflow: hidden;
}

.activity .aggregated_activities {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.activity .aggregated_activities .article {
  display: flex;
  flex-direction: row;
  padding-bottom: 0.5em;
  margin-top: 0.5em;
  margin-bottom: 0;
  border-bottom: #ccc solid 0.01em;
  flex-basis: 100%;
}

.activity .aggregated_activities > .article:last-child {
  border: none;
  padding-bottom: 0;
}

.activity .article > :nth-child(2) {
  margin-left: 0.5em;
  margin-right: 0.5em;
}
