.star_meter {
  display: flex;
  flex-direction: row;
}

.star_meter.active svg {
  cursor: pointer;
}

.star_meter.choosing .on {
  color: #999999;
}

.star_meter .on {
  padding: 0.1em;
  font-size: 1.75em;
  color: #4D4D4D;
  transition: color 2s;
}

.star_meter .off {
  padding: 0.1em;
  font-size: 1.75em;
  color: #bbb;
  transition: color 2s;
}
