.reaction_activity section {
  border-bottom: none;
  padding: 1em;
}

.reactions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #fafafa;
}

.reactions > div {
  font-size: 0.8em;
  margin: 0.5em;
  text-align: center;
}
